body{
    /* background-color: #f0f0f0; */
}

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

a {
    color: inherit;
    text-decoration: inherit;
}